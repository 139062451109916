import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Alert, Grid, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AddTechnologyAPI } from 'action/TechnologyAction';
import axios from 'axios';

const AddTechnology = () => {
    const navigate = useNavigate();
    const [name, setName] = useState(' ');
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [nameError, setNameError] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    console.log(message);

    const addTechnology = async (name) => {
        try {
            name = name.trim();
            const res = await axios.post('https://localhost:7212/api/v1/Technology', {
                name: name,
                isActive: true
            });
            if (res.data.data) {
                navigate('/technology');
            } else {
                if (res.data.statusCode == 400) {
                    const newErrors = res.data.errors.map((error) => error.message);
                    setErrors(newErrors);
                } else {
                    setErrors(['Something went wrong']);
                }
            }
        } catch (error) {
            if (error.response.data.statusCode == 400) {
                const newErrors = error.response.data.errors.map((error) => error.message);
                setErrors(newErrors);
            } else {
                setErrors(['Something went wrong']);
            }
        }
    };

    const handlePaste = (event) => {
        const inputString = event.clipboardData.getData('Text');
        const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        if (!regex.test(inputString)) {
            event.preventDefault();
        } else {
            setNameError('');
            setIsNameValid(true);
        }
    };

    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        const inputString = event.target.value + String.fromCharCode(event.charCode);

        if (!regex.test(inputString)) {
            setNameError('Technology name must contain at least one alphabet');
            setIsNameValid(false);
        } else {
            setNameError('');
            setIsNameValid(true);
        }
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.trim() === '') {
            setNameError('Technology name is required');
            setIsNameValid(false);
        } else if (isNameValid) {
            addTechnology(name);
        }
    };

    return (
        <Grid container justifyContent="center" mt={15}>
            <Grid
                item
                xs={12}
                sm={8}
                md={6}
                lg={6}
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    p: 7,
                    bgcolor: 'white'
                }}
            >
                <Typography variant="h2" mb={5}>
                    Create
                </Typography>
                <TextField
                    id="outlined-basic"
                    label="Enter technology"
                    autoComplete="off"
                    variant="outlined"
                    InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste } }}
                    mb={2}
                    sx={{ width: '100%' }}
                    onChange={(e) => setName(e.target.value)}
                    error={!!nameError}
                    helperText={nameError}
                />

                {errors.length > 0 && (
                    <Box sx={{ color: 'red', fontSize: '0.8rem', marginLeft: '-4%' }}>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, gap: 2, mt: 4 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{ color: 'white' }}
                        onClick={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        Save
                    </Button>
                    <Button variant="contained" color="error" sx={{ color: 'white' }} onClick={(e) => navigate('/technology')}>
                        Cancel
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AddTechnology;
