import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import CustomToggleButton from 'ui-component/extended/CustomToggleButton';
import { DeleteDesignation } from 'action/DesignationAction';
import { useDispatch } from 'react-redux';
import Error from 'views/errorResponse/Error';

const UpdateDesignation = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [designation, setDesignation] = useState('');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState([]);
    const [nameError, setNameError] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [isActive, setIsActive] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const GetDesignationById = async () => {
            try {
                const res = await axios.get(`https://localhost:7212/api/v1/Designation/${id}`);
                if (res) {
                    setDesignation(res.data.data.name);
                    setIsActive(res.data.data.isActive);
                    setName(res.data.data.name);
                } else {
                    setDesignation(undefined);
                }
            } catch (error) {
                setDesignation(undefined);
            }
        };
        GetDesignationById();
    }, []);

    useEffect(() => {
        const regex = /^[a-zA-Z\s]*$/;
        setIsNameValid(regex.test(designation));
    }, [designation]);

    const updateDesignation = async (id, name, isActive) => {
        try {
            if (name.trim() === '') {
                setNameError('Designation name is required');
            } else {
                name = name.trim();
                if (name) {
                    const res = await axios.put(`https://localhost:7212/api/v1/Designation/${id}`, {
                        id: id,
                        name: name,
                        isActive: isActive
                    });
                    if (res.data) {
                        navigate('/designation');
                    } else {
                        if ((res.data.statusCode = 400)) {
                            const newErrors = res.data.errors.map((error) => error.message);
                            setErrors(newErrors);
                        } else {
                            setErrors(['Something went wrong']);
                        }
                    }
                }
            }
        } catch (error) {
            if (error.response.data.statusCode == 400) {
                const newErrors = error.response.data.errors.map((error) => error.message);
                setErrors(newErrors);
            } else {
                setErrors(['Something went wrong']);
            }
        }
    };

    const handlePaste = (event) => {
        const inputString = event.clipboardData.getData('Text');
        const regex = /^[a-zA-Z\s-]*$/;
        if (!regex.test(inputString)) {
            event.preventDefault();
            // setNameError('Numbers and special characters not allowed');
            // setIsNameValid(false);
        } else {
            setNameError('');
            setIsNameValid(true);
        }
    };
    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        const regex = /^[a-zA-Z\s-]*$/;
        const inputString = event.target.value + String.fromCharCode(event.charCode);
        if (!regex.test(inputString)) {
            event.preventDefault();
            // setNameError('Numbers and special characters not allowed');
            // setIsNameValid(false);
        } else {
            setNameError('');
            setIsNameValid(true);
        }
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.trim() === '') {
            setNameError('Designation name is required');
            setIsNameValid(false);
        } else if (isNameValid) {
            updateDesignation(id, designation, isActive);
        }
    };

    const handleToggle = (status) => {
        setIsActive(!status);
    };

    return (
        <Box>
            {designation=== undefined ? (
                <>
                    <Error />
                </>
            ) : (
                <Grid container justifyContent="center" mt={15}>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={6}
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            p: 7,
                            bgcolor: 'white'
                        }}
                    >
                        <Typography variant="h2" mb={5}>
                            Update : {name}
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={designation}
                            mb={2}
                            sx={{ width: '100%' }}
                            InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste } }}
                            onChange={(e) => setDesignation(e.target.value)}
                            autoComplete="off"
                            error={!!nameError}
                            helperText={nameError}
                            disabled={!isActive}
                        />
                        {nameError ? (
                            <></>
                        ) : (
                            <>
                                {errors.length > 0 && (
                                    <Box sx={{ color: 'red', fontSize: '0.8rem', marginLeft: '-4%' }}>
                                        <ul>
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </Box>
                                )}
                            </>
                        )}
                        <CustomToggleButton handleToggle={handleToggle} status={isActive} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: '10px' }}>
                            <Button type="submit" variant="contained" color="secondary" sx={{ color: 'white' }} onClick={handleSubmit}>
                                Save
                            </Button>
                            <Button variant="contained" color="error" sx={{ color: 'white' }} onClick={(e) => navigate('/designation')}>
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default UpdateDesignation;
