import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    TablePagination,
    TableSortLabel,
    TextField,
    Typography
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Stack } from '@mui/system';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import axios from 'axios';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DeleteDesignation } from 'action/DesignationAction';
import { yellow } from '@mui/material/colors';
import WarningIcon from '@mui/icons-material/Warning';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export default function Designation() {
    const [designation, setDesignation] = useState();
    const [designationList, setDesignationList] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(window.localStorage.getItem('currentPage') || 1);
    const [rowsPerPage, setRowsPerPage] = useState(window.localStorage.getItem('rowsPerPage') || 10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isAscending, setIsAscending] = useState(false);
    const [sortBy, setSortBy] = useState('modifieddate');
    const [searchValue, setSearchValue] = useState(null);
    const [search, setSearch] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [deleteId, setDeleteId] = useState(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDesignation = async () => {
            try {
                if (!search) {
                    const res = await axios.get(
                        `https://localhost:7212/api/v1/Designation?PageNumber=${currentPage}&PageSize=${rowsPerPage}&SortBy=${sortBy}&IsAscending=${isAscending}`
                    );
                    if (res && res.data && res.data.data) {
                        setDesignation(res.data.data);
                        setIsLoading(false);
                    }
                } else {
                    let param = encodeURIComponent(search);
                    const res = await axios.get(
                        `https://localhost:7212/api/v1/Designation?Name=${param}&PageNumber=${currentPage}&PageSize=${rowsPerPage}&SortBy=${sortBy}&IsAscending=${isAscending}`
                    );
                    if (res && res.data && res.data.data) {
                        setDesignation(res.data.data);
                        setIsLoading(false);
                    } else {
                        emptySearch();
                    }
                }
            } catch (err) {
                emptySearch();
            }
        };
        fetchDesignation();
    }, [currentPage, rowsPerPage, sortBy, isAscending, search]);

    useEffect(() => {
        if (designation) {
            setDesignationList(designation.designations);
            setTotalPages(designation.totalPages);
            setCurrentPage(designation.currentPage);
            setTotalRecords(designation.totalCount);
            const newTotalPages = Math.ceil(designation.totalCount / rowsPerPage);
            if (newTotalPages <= currentPage) {
                setCurrentPage(newTotalPages);
            }
        }
    }, [designation, rowsPerPage, sortBy, isAscending, search]);

    const handlePageChange = (event, value) => {
        window.localStorage.setItem('currentPage', value);
        setCurrentPage(value);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        window.localStorage.setItem('rowsPerPage', event.target.value);
        const newTotalPages = Math.ceil(totalRecords / event.target.value);
        if (newTotalPages < currentPage) {
            setCurrentPage(newTotalPages);
        }
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && searchValue != null) {
            event.preventDefault();
            setSearch(searchValue);
        }
    };
    const emptySearch = () => {
        setSearchValue(null);
        if (designationList.length === 0) {
            setCurrentPage(1);
        }
    };
    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };
    return (
        <Box mt={7}>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                {isLoading ? (
                    <Typography mt={10} color="secondary.main">
                        <CircularProgress />
                    </Typography>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    background: 'transparent',
                                    position: 'relative'
                                }}
                            >
                                <Grid container direction="row" justifyContent="space-between" mb="3%">
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="dark"
                                            size="large"
                                            sx={{ display: 'flex', alignItems: 'center', gap: '5px', maxWidth: '90px' }}
                                            onClick={(e) => navigate('/designation/add')}
                                        >
                                            <AddCircleSharpIcon /> <span>New</span>
                                        </Button>
                                    </div>
                                    <Stack direction="row" alignItems="right" spacing={1}>
                                        <TextField
                                            sx={{ minWidth: '250px' }}
                                            size="small"
                                            InputProps={{ inputProps: { onKeyPress: handleKeyPress, maxLength: 30 } }}
                                            label="Search"
                                            type="search"
                                            onChange={(e) => {
                                                if (e.target.value === '') {
                                                    setSearchValue(null);
                                                    setSearch(null);
                                                } else {
                                                    setSearchValue(e.target.value);
                                                }
                                            }}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div>
                                            <Button
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                variant="outlined"
                                                color="dark"
                                                size="large"
                                                onClick={(e) => {
                                                    if (searchValue != null) setSearch(searchValue);
                                                }}
                                            >
                                                <SearchSharpIcon fontSize="medium" />
                                            </Button>
                                        </div>
                                    </Stack>
                                </Grid>
                            </Box>
                            {totalRecords !== 0 ? (
                                <>
                                    <Box
                                        sx={{
                                            background: 'transparent',
                                            position: 'relative',
                                            zIndex: 1
                                        }}
                                    >
                                        <Paper zIndex="1" direction="row" sx={{ minWidth: '53%' }}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>
                                                                <TableSortLabel
                                                                    active={sortBy === 'name'}
                                                                    direction={isAscending ? 'asc' : 'desc'}
                                                                    onClick={() => {
                                                                        setIsAscending(!isAscending);
                                                                        setSortBy('name');
                                                                    }}
                                                                    sx={{
                                                                        color: 'secondary.light',
                                                                        '&:hover': {
                                                                            color: 'secondary.light'
                                                                        }
                                                                    }}
                                                                >
                                                                    Designation
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <TableSortLabel
                                                                    active={sortBy === 'isActive'}
                                                                    direction={isAscending ? 'asc' : 'desc'}
                                                                    onClick={() => {
                                                                        setIsAscending(!isAscending);
                                                                        setSortBy('isActive');
                                                                    }}
                                                                    sx={{
                                                                        color: 'secondary.light',
                                                                        '&:hover': {
                                                                            color: 'secondary.light'
                                                                        }
                                                                    }}
                                                                >
                                                                    Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {designationList.map((designation) => {
                                                            return (
                                                                <StyledTableRow>
                                                                    <StyledTableCell component="th" scope="row" sx={{ width: 270 }}>
                                                                        {designation.name}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell sx={{ width: 220 }}>
                                                                        {designation.isActive ? (
                                                                            <>
                                                                                <Chip
                                                                                    label="Active"
                                                                                    color="success"
                                                                                    size="small"
                                                                                    sx={{ width: 70 }}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Chip
                                                                                    label="Inactive"
                                                                                    color="error"
                                                                                    size="small"
                                                                                    sx={{ width: 70 }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <Button
                                                                            color="dark"
                                                                            onClick={(e) =>
                                                                                navigate(`/designation/update/${designation.id}`)
                                                                            }
                                                                        >
                                                                            <BorderColorIcon />
                                                                        </Button>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                                <Divider />
                                                {rowsPerPage && totalRecords && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        component="div"
                                                        count={totalRecords}
                                                        rowsPerPage={rowsPerPage}
                                                        page={currentPage - 1}
                                                        onPageChange={handlePageChange}
                                                        nextIconButtonProps={{ onClick: handleNextPage }}
                                                        backIconButtonProps={{ onClick: handlePreviousPage }}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            background: 'transparent',
                                            position: 'relative',
                                            zIndex: 1
                                        }}
                                    >
                                        <Paper zIndex="1" direction="row" sx={{ minWidth: '53%' }}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>Designation</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={9} sx={{ width: '585px' }}>
                                                                <Typography color="red" fontWeight="bold">
                                                                    No records found
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}
