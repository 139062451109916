const DesignationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_DESIGNATION_SUCCESS':
            return {
                designation: action.payload,
                message: 'Success'
            };
        case 'DESIGNATION_ADD_SUCCESS':
            return {
                //addMessage: action.payload,
                addMessage: action.payload,
                message: 'Success'
            };
        case 'DESIGNATION_ADD_FAILED':
            return {
                addMessage: action.payload,
                message: 'Failed'
            };
        case 'FETCH_DESIGNATION_BY_ID_SUCCESS':
            return {
                //addMessage: action.payload,
                designation: action.payload,
                message: 'Success'
            };
        case 'FETCH_DESIGNATION_BY_ID_FAILED':
            return {
                designation: action.payload,
                message: 'Failed'
            };
        case 'UPDATE_DESIGNATION_SUCCESS':
            return {
                editMessage: action.payload,
                message: 'Success'
            };
        case 'UPDATE_DESIGNATION_FAILED':
            return {
                editMessage: action.payload,
                message: 'Failed'
            };
        case 'DELETE_DESIGNATION_SUCCESS':
            return {
                editMessage: action.payload,
                message: 'Success'
            };
        case 'DELETE_DESIGNATION_FAILED':
            return {
                editMessage: action.payload,
                message: 'Failed'
            };
        case 'RESET_DESIGNATION':
            return {
                //addMessage: action.payload,
                designation: {},
                message: 'Success'
            };
        default:
            return state;
    }
};

export default DesignationReducer;
