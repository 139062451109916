import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { bgcolor } from '@mui/system';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';

const AddInHouseProject = () => {
    const [name, setName] = useState('');
    const [errors, setErrors] = useState([]);
    const [nameError, setNameError] = useState('');
    const [descriptionerror, setDescriptionError] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [isDescriptionValid, setIsDescriptionValid] = useState(false);
    const [description, setDescription] = useState('');
    const [domainData, setDomainData] = useState(0);
    const [domainOptions, setDomainOptions] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDomainChange = (event) => {
        setDomainData(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const options =
        domainOptions.length > 0
            ? domainOptions.map((domain) => {
                  return { value: domain.id, label: domain.name };
              })
            : [];
    console.log('options array');
    console.log(domainOptions);
 useEffect(() => {
        axios
            .get(`https://localhost:7212/api/v1/Domain/All`)
            .then((response) => {
                setDomainOptions(response.data.data);
                console.log('Domain', response);
            })
            .catch((error) => console.log(error));
    }, []);

    const addInHouseProject = async (name, domains, description) => {
        console.log('project', name, domains, description);
        try {
            if (name.trim() && description.trim() === '') {
                setNameError('Project name is required');
                setDescriptionError('Description is required');
            }
            const res = await axios.post('https://localhost:7212/api/v1/InHouseProject', {
                name: name,
                domainId: domains,
                description: description,
                createdBy: 'Admin',
                createdDate: '2023-03-15T09:46:13.324Z',
                isActive: true
            });
            if (res.data.data) {
                navigate('/inhouseproject');
            } else {
                if (res.data.statusCode == 400) {
                    const newErrors = res.data.errors.map((error) => error.message);
                    setErrors(newErrors);
                } else {
                    setErrors(['Something went wrong']);
                }
            }
        } catch (error) {
            if (error.response.data.statusCode == 400) {
                console.log(error);
                const newErrors = error.response.data.errors.map((error) => error.message);
                setErrors(newErrors);
            } else {
                setErrors(['Something went wrong']);
            }
        }
    };
    const handlePaste = (event) => {
      const inputString = event.clipboardData.getData('Text');
      const regex = /^[a-zA-Z0-9\s]*$/;
      const emojiRegex = /[^\u0000-\u007F]+/g;
      if (!regex.test(inputString)) {
        event.preventDefault();
        //   setNameError('Special characters not allowed');
        //   setIsNameValid(false);
      } else {
          setNameError('');
          setIsNameValid(true);
      }
  };
    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        const regex = /^[a-zA-Z0-9\s]*$/;
        const emojiRegex = /[^\u0000-\u007F]+/g;
        const inputString = event.target.value + String.fromCharCode(event.charCode);
            if (!regex.test(inputString)) {
                event.preventDefault();
                // setNameError('Special characters are not allowed');
                // setIsNameValid(false);
            } else {
                setNameError('');
                setIsNameValid(true);           
        }
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        if (name.trim() === '') {
          setNameError('Project name is required');
          setIsNameValid(false);
        } else {
          setIsNameValid(true);
      
          if (description.trim() === '') {
            setDescriptionError('Description is required');
            setIsDescriptionValid(false);
          } else {
            setIsDescriptionValid(true);
            addInHouseProject(name, domainData, description);
          }
        }
      };

    return (
        <Grid container justifyContent="center" mt={7}>
            <Grid
                item
                xs={12}
                sm={8}
                md={6}
                lg={6}
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    p: 7,
                    bgcolor: 'white'
                }}
            >
                <Typography variant="h2" mb={5}>
                    Create
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        id="projectName"
                        label="Enter project"
                        variant="outlined"
                        mb={2}
                        sx={{ width: '100%' }}
                        autoComplete="off"
                        InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste ,onContextMenu: (event) => {
                            event.preventDefault();
                        }} }}
                        error={!!nameError}
                        helperText={nameError}
                        onChange={handleNameChange}
                        //onKeyDown={handleKeyDown}
                    />

                    <FormControl>
                        <InputLabel> Select domain</InputLabel>
                        <Select
                            displayEmpty
                            labelId="my-select-label"
                            id="my-select"
                            label="Select domain"
                            sx={{ width: '100%' }}
                            onChange={(domainData) => {
                                setDomainData(domainData.target.value);
                                {
                                    console.log('Value', domainData.target.value);
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        overflowY: 'auto'
                                    }
                                }
                            }}
                        >
                            {options.map(({ value, label }, index) => (
                                <MenuItem key={index} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="projectDescription"
                        label="Enter description"
                        variant="outlined"
                        mb={2}
                        multiline
                        rows={4}
                        sx={{ width: '100%' }}
                        InputProps={{ inputProps: { onKeyPress: handleKeyPress , onPaste: handlePaste  } }}
                        onChange={handleDescriptionChange}
                        error={!!descriptionerror}
                        helperText={descriptionerror}
                    /> 

                     {nameError || descriptionerror ? (
                        <></>
                    ) : (
                        <>
                            {errors.length > 0 && (
                                <Box sx={{ color: 'red', fontSize: '0.8rem', marginLeft: '-4%' }}>
                                    <ul>
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </>
                    )} 
                </Box>
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, gap: 2, mt: 4 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{ color: 'white' }}
                        onClick={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        Save
                    </Button>
                    <Button variant="contained" color="error" sx={{ color: 'white' }} onClick={(e) => navigate('/inhouseproject')}>
                        Cancel
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AddInHouseProject;