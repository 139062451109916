import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Error from 'views/errorResponse/Error';
import { showSnackbar } from 'action/SnackbarAction';
import { useDispatch } from 'react-redux';
import CustomToggleButton from 'ui-component/extended/CustomToggleButton';
import axios from 'axios';

const UpdateInHouseProject = () => {
    const Params = useParams();
    const [name, setName] = useState("");
    const [errors, setErrors] = useState([]);
    const [nameError, setNameError] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [descriptionerror, setDescriptionError] = useState('');
    const [isDescriptionValid, setIsDescriptionValid] = useState(false);
    const [domain, setDomain] = useState('');
    const [domainId, setDomainId] = useState(0);
    const [projectDomainId, setProjectDomainId] = useState(0);
    const [inhouseproject, setInHouseProject] = useState('');
    const [options, setOptions] = useState([]);
    const [description, setDescription] = useState("");
    const [isActive, setIsActive] = useState();
    console.log('description', description);
    const [domainOptions, setDomainOptions] = useState([]);

    const [id, setId] = useState(Params.id);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log(id);
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDomainChange = (event) => {
        setDomain(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };
    const handlePaste = (event) => {
        const inputString = event.clipboardData.getData('Text');
        const regex = /^[a-zA-Z0-9\s]*$/;
        if (!regex.test(inputString)) {
            event.preventDefault();
            // setNameError('Special characters not allowed');
            // setIsNameValid(false);
        } else {
            setNameError('');
            setIsNameValid(true);
        }
    };
    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        const regex = /^[a-zA-Z0-9\s]*$/;
        const inputString = event.target.value + String.fromCharCode(event.charCode);
        if (!regex.test(inputString)) {
            event.preventDefault();
            // setNameError('special characters not allowed');
            // setIsNameValid(false);
        } else {
            setNameError('');
            setIsNameValid(true);
        }
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        if (name.trim() === '') {
          setNameError('Project name is required');
          setIsNameValid(false);
        } else {
          setIsNameValid(true);
      
          if (description.trim() === '') {
            setDescriptionError('Description is required');
            setIsDescriptionValid(false);
          } else {
            setIsDescriptionValid(true);
           updateInHouseProject(name, projectDomainId, description,isActive);
          }
        }
      };

    useEffect(() => {
        const GetInHouseProjectById = async () => {
            try {
                const res = await axios.get(`https://localhost:7212/api/v1/InHouseProject/${id}`);
                if (res) {
                    setName(res.data.name);
                    setDomainId(res.data.domainId);
                    setInHouseProject(res.data.name);
                    setDescription(res.data.description);
                    setProjectDomainId(res.data.domainId);
                    setIsActive(res.data.isActive);
                    console.log('Domain', res.data.domainId);
                    // console.log('project', inhouseproject);
                } else {
                    setInHouseProject(undefined);
                    console.log('project', inhouseproject);
                    console.log('inelse');
                }
            } catch (error) {
                console.log(error);
                setInHouseProject(undefined);
                console.log('project', inhouseproject);
                console.log('in catch');
            }
        };

        GetInHouseProjectById();
    }, []);

    console.log('InhouseProject', name, domainId, description);
    useEffect(() => {
        axios
            .get(
                `https://localhost:7212/api/v1/Domain/All`
            )
            .then((response) => {
                const selectOptions = response.data.data.map((domains) => {
                    return { value: domains.id, label: domains.name };
                });
                setDomainOptions(selectOptions);
                const defaultValue = selectOptions.find((option) => option.value === data.projectDomainId);
                setDomain(defaultValue);
                setProjectDomainId(defaultValue.value);
                console.log('defaultValue', defaultValue.value);
            })
            .catch((error) => console.log(error));
    }, []);

const updateInHouseProject = async ( name, domains, description,isActive) => {
    try {
        if (name.trim() === '' && description.trim() === '') {
            setNameError('Project name is required');
            setDescriptionError('Description is required');
        } else {
            name=name.trim();
            const res = await axios.put(`https://localhost:7212/api/v1/InHouseProject/${id}`, {
                id:id,
                name: name,
                domainId: domains,
                description: description,
                isActive:isActive
            });
            if (res.data) {
                navigate('/inhouseproject');
            } else {
                if ((res.data.statusCode = 400)) {
                    const newErrors = res.data.errors.map((error) => error.message);
                    setErrors(newErrors);
                } else {
                    setErrors(['Something went wrong']);
                }
            }
        

     }
    } catch (error) {
        if (error.response.data.statusCode == 400) {
            const newErrors = error.response.data.errors.map((error) => error.message);
            setErrors(newErrors);
        } else {
            setErrors(['Something went wrong']);
        }
       // console.log("error occured");
    }
};

const handleToggle = (status) => {
    setIsActive(!status);
};
    console.log('options array');
    console.log(domainOptions);

    return (
        <Box>
            {inhouseproject !== undefined ? (
                <Grid container justifyContent="center" mt={3}>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={6}
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            p: 7,
                            bgcolor: 'white'
                        }}
                    >
                        <Typography variant="h2" mb={5}>
                            Update : {inhouseproject}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <TextField
                                id="projectName"
                                variant="outlined"
                                mb={2}
                                sx={{ width: '100%' }}
                                autoComplete="off"
                                InputProps={{ inputProps: { onKeyPress: handleKeyPress,onPaste: handlePaste  } }}
                                onChange={handleNameChange}
                                error={!!nameError}
                                helperText={nameError}
                                value={name}
                                disabled={!isActive}
                            />

                            <Select
                                sx={{ width: '100%' }}
                                onChange={(e) => {
                                    setDomainId(e.target.value);
                                    {
                                        console.log('Value', e.target.value);
                                    }
                                    setProjectDomainId(e.target.value);
                                    {
                                        console.log('value', e.target.value);
                                    }
                                }}
                                value={projectDomainId || ''}
                                disabled={!isActive}

                                MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                        overflowY: 'auto',
                                      },
                                    },
                                  }}
                            >
                                {domainOptions.map(({ value, label }, index) => {
                                    console.log(value, label);
                                    return (
                                        <MenuItem key={index} value={value}>
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                                {console.log('domain id and options', domainOptions, domainId)}
                            </Select>

                            <TextField
                                id="projectDescription"
                                InputProps={{ inputProps: { onKeyPress: handleKeyPress ,onPaste: handlePaste,onContextMenu: (event) => {
                                    event.preventDefault();
                                } } }}
                                variant="outlined"
                                mb={2}
                                multiline
                                rows={5}
                                sx={{ width: '100%' }}
                                onChange={handleDescriptionChange}
                                error={!!descriptionerror}
                                helperText={descriptionerror}
                                value={description}
                                disabled={!isActive}
                            />
                             {nameError || descriptionerror ? (
                        <></>
                    ) : (
                        <>
                            {errors.length > 0 && (
                                <Box sx={{ color: 'red', fontSize: '0.8rem', marginLeft: '-4%' }}>
                                    <ul>
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </>
                    )}
                        </Box>
                        <CustomToggleButton handleToggle={handleToggle} status={isActive}  />
                        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, gap: 2, mt: 4 }}>
                            <Button
                               type="submit" variant="contained" color="secondary" sx={{ color: 'white' }}  onClick={(e) => {
                                handleSubmit(e);
                               }}>
                                Save
                            </Button>

                            <Button variant="contained" color="error" sx={{ color: 'white' }} onClick={(e) => navigate('/inhouseproject')}>
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <Error />
            )}
        </Box>
    );
};
export default UpdateInHouseProject;
