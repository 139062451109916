import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Alert, Grid, Snackbar } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import Technology from './Technology';
import Error from 'views/errorResponse/Error';
import CustomToggleButton from 'ui-component/extended/CustomToggleButton';

const UpdateTechnology = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    const [technology, setTechnology] = useState('');
    const [message, setMessage] = useState();
    const [status, setStatus] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [name, setName] = useState(' ');
    const [errors, setErrors] = useState([]);
    const [nameError, setNameError] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [isActive, setIsActive] = useState();

    console.log(id);

    useEffect(() => {
        const GetTechnologyById = async () => {
            try {
                const res = await axios.get(`https://localhost:7212/api/v1/Technology/${id}`);
                if (res) {
                    console.log(res);

                    setTechnology(res.data.name);
                    setIsActive(res.data.isActive);
                    setName(res.data.name);
                } else {
                    setTechnology(undefined);
                }
            } catch (error) {
                setTechnology(undefined);
                console.log(error);
            }
        };
        GetTechnologyById();
    }, []);

    useEffect(() => {
        const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        setIsNameValid(regex.test(technology));
    }, [technology]);

    const updateTechnology = async (id, name, isActive) => {
        try {
            if (name.trim() === '') {
                setNameError('Technology name is required');
            } else {
                if (name) {
                    const res = await axios.put(`https://localhost:7212/api/v1/Technology/${id}`, {
                        id: id,
                        name: name,
                        isActive: isActive
                    });
                    if (res.data) {
                        navigate('/technology');
                    } else {
                        if ((res.data.statusCode = 400)) {
                            const newErrors = res.data.errors.map((error) => error.message);
                            setErrors(newErrors);
                        } else {
                            setErrors(['Something went wrong']);
                        }
                    }
                }
            }
        } catch (error) {
            if (error.response.data.statusCode == 400) {
                const newErrors = error.response.data.errors.map((error) => error.message);
                setErrors(newErrors);
            } else {
                setErrors(['Something went wrong']);
            }
        }
    };

    const handlePaste = (event) => {
        const inputString = event.clipboardData.getData('Text');
        const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        if (!regex.test(inputString)) {
            // setNameError('Technology name must contain at least one alphabet');
            // setIsNameValid(false);
             event.preventDefault();
        } else {
            setNameError('');
            setIsNameValid(true);
        }
    };

    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        const inputString = event.target.value + String.fromCharCode(event.charCode);
        if (!regex.test(inputString)) {
            setNameError('Technology name must contain at least one alphabet');
            setIsNameValid(false);
            console.log('invalid');
        } else {
            setNameError('');
            setIsNameValid(true);
            console.log('valid');
        }
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.trim() === '') {
            setNameError('Technology name is required');
            setIsNameValid(false);
        } else if (isNameValid) {
            updateTechnology(id, technology, isActive);
            console.log('valid and value updated');
        }
    };

    const handleToggle = (status) => {
        setIsActive(!status);
    };

    return (
        <Box>
            {technology === undefined ? (
                <>
                    <Error />
                </>
            ) : (
                <Grid container justifyContent="center" mt={15}>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={6}
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            p: 7,
                            bgcolor: 'white'
                        }}
                    >
                        <Typography variant="h2" mb={5}>
                            Update : {name}
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={technology}
                            autoComplete="off"
                            mb={2}
                            sx={{ width: '100%' }}
                            InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste } }}
                            onChange={(e) => {
                                setTechnology(e.target.value);
                                console.log(technology);
                            }}
                            error={!!nameError}
                            helperText={nameError}
                            disabled={!isActive}
                        />
                        {nameError ? (
                            <></>
                        ) : (
                            <>
                                {errors.length > 0 && (
                                    <Box sx={{ color: 'red', fontSize: '0.8rem', marginLeft: '-4%' }}>
                                        <ul>
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </Box>
                                )}
                            </>
                        )}
                        <CustomToggleButton handleToggle={handleToggle} status={isActive} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: '10px' }}>
                            <Button type="submit" variant="contained" color="secondary" sx={{ color: 'white' }} onClick={handleSubmit}>
                                Save
                            </Button>
                            <Button variant="contained" color="error" sx={{ color: 'white' }} onClick={(e) => navigate('/technology')}>
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default UpdateTechnology;
