// import { createStore } from 'redux';
import reducer from './reducer';
import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
    userLogin: { userInfo: userInfoFromStorage }
};

const middleware = [thunk];
// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
const persister = 'Free';

export { store, persister };
