import DesignationReducer from 'reducers/DesignationReducer';
import DomainReducer from 'reducers/DomainReducer';
import SnackbarReducer from 'reducers/SnackbarReducer';
import TechnologyReducer from 'reducers/TechnologyReducer';
import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userListReducer,
    userDeleteReducer,
    userUpdateReducer
} from './userReducers';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    adminDesgination: DesignationReducer,
    adminDomain:DomainReducer,
    adminTechnology: TechnologyReducer,
    customSnackbar:SnackbarReducer
});

export default reducer;
