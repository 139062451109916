import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Designation from 'views/pages/admin/Designation/Designation';
import AddDesignation from 'views/pages/admin/Designation/AddDesignation';
import UpdateDesignation from 'views/pages/admin/Designation/UpdateDesignation';
import Technology from 'views/pages/admin/Technology/Technology';
import AddTechnology from 'views/pages/admin/Technology/AddTechnology';
import UpdateTechnology from 'views/pages/admin/Technology/UpdateTechnology';
import Domain from 'views/pages/admin/Domain/Domain';
import AddDomain from 'views/pages/admin/Domain/AddDomain';
import UpdateDomain from 'views/pages/admin/Domain/UpdateDomain';
import InHouseProject from 'views/pages/admin/InHouseProject/InHouseProject';
import AddInHouseProject from 'views/pages/admin/InHouseProject/AddInHouseProject';
import UpdateInHouseProject from 'views/pages/admin/InHouseProject/UpdateInHouseProject';
import NotFound from 'views/errorResponse/NotFound';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '*',
            element: <NotFound/>
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: '/designation',
            element: <Designation />,
            exact: true
        },
        {
            path: '/designation/add',
            element: <AddDesignation />
        },
        {
            path: '/designation/update/:id',
            element: <UpdateDesignation />
        },
        {
            path: 'technology',
            element: <Technology />
        },
        {
            path: '/technology/add',
            element: <AddTechnology />
        },
        {
            path: '/technology/update/:id',
            element: <UpdateTechnology />
        },
        {
            path: '/domain',
            element: <Domain />
        },
        {
            path: '/domain/add',
            element: <AddDomain />
        },
        {
            path: '/domain/update/:id',
            element: <UpdateDomain />
        },
        {
            path: 'inhouseproject',
            element: <InHouseProject />
        },
        {
            path: '/inhouseproject/add',
            element: <AddInHouseProject />
        },
        {
            path: '/inhouseproject/update/:id',
            element: <UpdateInHouseProject />
        }
    ]
};

export default MainRoutes;
