// assets
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import LaptopChromebookOutlinedIcon from '@mui/icons-material/LaptopChromebookOutlined';


// constant
const icons = {
    PortraitOutlinedIcon,
    HubOutlinedIcon,
    WysiwygOutlinedIcon,
    LaptopChromebookOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Settings',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'designation',
            title: 'Designations',
            type: 'item',
            icon: icons.PortraitOutlinedIcon,
            url: '/designation'
        },
        {
            id: 'domain',
            title: 'Domains',
            type: 'item',
            icon: icons.HubOutlinedIcon,
            url: '/domain'
        },
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            icon: icons.WysiwygOutlinedIcon,
            url: '/inhouseproject'
        },
        {
            id: 'technology',
            title: 'Technologies',
            type: 'item',
            icon: icons.LaptopChromebookOutlinedIcon,
            url: '/technology'
        }
    ]
};

export default pages;
