import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { orange, red, yellow } from '@mui/material/colors';

export default function Error() {
    return (
        <Box sx={{ minHeight: '30vh', maxWidth: '50%', marginTop: '5%', marginLeft: '15%' }}>
            <Grid container justifyContent="center" alignItems="center" height="100%" paddingTop="5%">
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={12}
                    lg={8}
                    component="form"
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        boxShadow: '0px 3px 15px rgba(211, 84, 0);, 1) ',
                        color: red[500],
                        display: 'flex',
                        alignItems: 'center',
                        p: 7
                    }}
                >
                    <DangerousIcon sx={{ color: red[500], mr: 3, fontSize: '3rem' }} />
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                        A problem has been occured
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
