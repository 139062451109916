// assets
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
// constant
const icons = { LibraryBooksOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Resumes',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.LibraryBooksOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
